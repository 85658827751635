.subsection {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 4px;
  display: grid;
  grid-template: auto / auto;
  row-gap: 30px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

/* Medium screen (Tablet) */
@media only screen and (min-width: 600px) {}

/* Big screen like laptop and desktop pc*/
@media only screen and (min-width: 768px) {
  .subsection{
    box-shadow: 2px 2px 50px 2px #1569b733;
  }
}


/*Small screen (Mobile) */
.questionaire-header {
  background-color: #efefef;
  width: 100vw;
}

.questionaire-header-right{
  display: none;
}

.questionaire-header .content .title {
  width: 100vw;
  display: grid;
  grid-template-columns: min-content auto;
  font-size: 20px;
  font-weight: 600;
  padding: 16px 0px 0 24px;
  align-items: center;
}

.questionaire-header .content .questionaire-header-body {
  width: 100vw;
  font-size: 14px;
  font-weight: 400;
  padding: 16px;
  align-items: center;
}

/* Medium screen (Tablet) */
@media only screen and (min-width: 600px) {}

/* Big screen like laptop and desktop pc*/
@media only screen and (min-width: 768px) {
  .questionaire-header{
    width:100%;
  }

  .questionaire-header-right{
    display: block;
  }

  .questionaire-header .content {
    color: #333333;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
  }

  .questionaire-header .content .title {
    width:100%;
    font-size: 50px;
    font-weight: 400;
    padding: 60px 0px 20px 60px;
    align-items: center;
  }

  .questionaire-header .content .title .qr-image {
    margin-left: 40px;
  }

  .questionaire-header .content .questionaire-header-body {
    width: 100%;
    font-size: 18px;
    font-weight: 400;
    padding: 0px 0px 60px 60px;
    align-items: center;
  }
}

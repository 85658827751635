.patient-form-summary {
  display: grid;
  grid-template: auto auto/100%;
  row-gap: 25px;
  background-color: #fafafa;
}

.patient-form-summary-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  color: #333;
  font-size: 22px;
}

.patient-form-summary-header button {
  background-color: #549bdc;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  border: none;
  border-radius: 2px;
  height: 40px;
  width: 137px;
  font-weight: 200;
  font-size: 15px;
  cursor: pointer;
}

.patient-form-summary-text-area {
  padding-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
}

#patient_form_summary_text {
  height: 100px;
  padding: 2%;
  width: 96%;
  text-indent: 0px;
  padding-left: 4%;
}

.patient_form_summary_text {
  height: 100px;
  padding: 2%;
  width: 96%;
  /* text-indent: 0px;
  padding-left: 4%; */
}

#patient_form_summary_text::placeholder {
  font-family: inherit;
  font-style: italic;
  text-indent: 0px;
}

.patient-form-summary-text-area .text-area-input {
  height: auto;
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 0px;
}

.patient-form-summary-title-with-loader {
  display: flex;
  align-items: center;
}

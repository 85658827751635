.assistant-form-tab-bar {
  color: #549bdc;
}

.assistant-form-tab-bar-button {
  background-color: transparent;
  color: #549bdc;
  font-family: inherit;
  border: none;
  padding-left: 5px;
  padding-right: 5px;
  height: 35px;
  font-weight: 400;
  font-size: 1rem;
  cursor: pointer;
}

.assistant-form-tab-bar-button-current {
  color: #3a72a6;
  border-bottom: 1px solid;
}

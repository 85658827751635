.endo-range {
  width: 90%;
  padding: 24px 16px 0 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 30px;
}

.endo-range-input {
  width: 90%;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  margin: 0 auto;
  margin-top: 30px;
}

/* Medium screen (Tablet) */
@media only screen and (min-width: 600px) {}

/* Big screen like laptop and desktop pc*/
@media only screen and (min-width: 768px) {
  .endo-range {
    padding: 0;
  }
}
.container-questions {
  width: 100vw;
  max-width: 1094px;
}

.current-issue,
.dental-history,
.medical-history-form {
  background-color: #fafafa;
  color: #3a72a6;
  width: 100%;
}

/* Medium screen (Tablet) */
@media only screen and (min-width: 600px) {
}

/* Big screen like laptop and desktop pc*/
@media only screen and (min-width: 768px) {
  .container-questions {
    width: calc(100vw - 180px);
    margin: 90px;
  }

  .current-issue,
  .dental-history,
  .medical-history-form {
    padding: 0px 60px;
  }

  .current-issue-form,
  .dental-history-form,
  .medical-history-form {
    width: 100%;
    display: grid;
    column-gap: 60px;
    row-gap: 30px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    justify-content: center;
    color: #3a72a6;
  }

  #how_to_improve_experience {
    height: 153px;
  }

  #to_dental_history,
  #to_finish,
  #to_medical_history {
    margin-top: 50px;
  }
}

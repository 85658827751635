@import url("https://fonts.googleapis.com/css2?family=Kulim+Park&family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300&display=swap");
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Kulim Park", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 18px;
  font-weight: 400;
  background-color: #549bdc;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.endo-app {
  background-color: #549bdc;
}

.app-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error {
  color: #ff4f6e;
  border: solid 1px #ff4f6e;
}

/* Medium screen (Tablet) */
@media only screen and (min-width: 600px) {
}

/* Big screen like laptop and desktop pc*/
@media only screen and (min-width: 768px) {
  .container {
    background-color: #fafafa;
    padding-bottom: 80px;
  }

  .span-1 {
    grid-column: span 1;
  }

  .span-2 {
    grid-column: span 2;
  }

  ::placeholder {
    font-family: inherit;
    font-style: italic;
    text-indent: 16px;
  }

  .form-label {
    width: 100%;
  }

  .form-label-disabled {
    width: 100%;
    color: #c9d3dd;
  }

  /* modal styling */
  .modal-wrapper {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(107, 114, 128, 0.5);
    z-index: 9;
    width: 100%;
    min-height: 100vh;
  }

  .modal {
    position: relative;
    background-color: #fff;
    width: 1094px;
    height: 634px;
    border: 1px solid rgb(179, 179, 179);
    border-radius: 4px;
    overflow: hidden;
    margin: 100px 15px 160px;
    padding: 20px;
  }

  .alert {
    position: relative;
    width: 473px;
    height: 80px;
    overflow: hidden;
    margin: 100px 15px 160px;
    padding: 20px;
  }
}

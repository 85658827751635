.logout-element {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.profile-logout {
  display: flex;
  align-items: center;
  column-gap: 20px;
  color: #333;
}

.profile-logout-divider {
  width: 1px;
  height: 40px;
  background-color: black;
}

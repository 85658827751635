.assistant-form-header {
  display: grid;
  grid-template: 100px /320px auto 320px;
  background-color: #efefef;
  width: 100%;
  height: 100px;
  align-items: center;
  /* position: fixed; */
}

.assistant-form-back-button {
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  margin-left: 25px;
  margin-top: 0px;
}
.assistant-form-back-button img {
  margin-right: 12px;
}

.span-1.text-field{
  width: 90%;
}

.text-field {
  padding: 24px 16px 0 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
}

.text-field-input {
  width: 100%;
  height: 50px;
  margin-top: 4px;
  border: solid 1px #b0d6f9;
  border-radius: 4px;
  text-indent: 16px;
  font-size: 16px;
}

.text-field-input::placeholder {
  font-size: 14px;
}

.error {
  color: #ff4f6e;
  border: solid 1px #ff4f6e;
}

.error::placeholder {
  color: #ff4f6e;
}

.text-field-input:focus {
  outline: none !important;
  border: solid 1px #549bdc;
}

.text-field-input:disabled {
  border: solid 1px #c9d3dd;
  background-color: #fff;
}

/* Medium screen (Tablet) */
@media only screen and (min-width: 600px) {}

/* Big screen like laptop and desktop pc*/
@media only screen and (min-width: 768px) {
  @media (hover) {
    .text-field-input:hover {
      outline: none !important;
      border: solid 1px #3a72a6;
    }
  }

  .text-field {
    padding: 0;
  }
}
/*Small screen (Mobile) */
.pagenotfound-headers{
  color: #FFFFFF;
  text-align: center;
}

.pagenotfound-headers h1{
  font-size: 40px;
}

.pagenotfound-instruction {
  color: #ffffff;
  text-align: center;
}

.pagenotfound-illustration img {
  display: block;
  margin: auto;
}

.pagenotfound-instruction {
  font-size: 14px;
  margin: 0 20%;
}

#go_back {
  color: #ffffff;
  background-color: #32659c;
}

/* Medium screen (Tablet) */
@media only screen and (min-width: 600px) {}

/* Big screen like laptop and desktop pc*/
@media only screen and (min-width: 768px) {
  .pagenotfound-headers h1{
    font-size: 70px;
  }

  .pagenotfound-instruction {
    font-size: 24px;
    margin: 0;
  }
}
/*Small screen (Mobile) */
.tab-select{
  display: none;
}

.tab-select-mobile{
  width: 100vw;
  height: 45px;
}

.tab-section-container{
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 10% 80% 10%;
  justify-content: center;
  align-items: center;
}

.tab-button-current, .headingTab {
  border: 0;
  font-size: 21px;
  font-weight: 700;
  background-color: #549bdc;
  color: #ffffff;
}

.tab-button-current{
  width: 24px;
}

.headingTab{
  text-align: center;
}

/* Medium screen (Tablet) */
@media only screen and (min-width: 600px) {}

/* Big screen like laptop and desktop pc*/
@media only screen and (min-width: 768px) {
  .tab-select-mobile{
    display: none;
  }

  .tab-select {
    background-color: #FAFAFA;
    display: grid;
    grid-template-columns: auto max-content auto max-content auto;
    align-items: center;
    padding: 60px;
  }

  .tab-button {
    background-color: #b0d6f9;
    color: #3a72a6;
    font-family: "Open Sans Condensed", sans-serif;
    border: none;
    border-radius: 30px;
    padding-left: 18px;
    padding-right: 18px;
    height: 60px;
    font-stretch: condensed;
    font-weight: 700;
    font-size: 22px;
    cursor: pointer;
  }

  .tab-button-current {
    width: auto;
    background-color: #549bdc;
    color: #ffffff;
  }

  .tab-button-filled {
    background-color: #32659c;
    color: #549bdc;
  }

  .tab-button-hover {
  }

  .tab-divider-line {
    background-color: #b0d6f9;
    width: 60px;
    height: 1.5px;
    position: inline;
  }
}
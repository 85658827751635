.other-treatment {
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  margin-top: 50px;
  padding: 20px;
  font-family: "Open Sans", sans-serif;
}

.other-treatment-consent-wrapper .checkbox-label {
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  font-weight: 100;
}

.other-treatment-text-row {
  color: #3a72a6;
  margin-top: 20px;
  display: flex;
  gap: 50px;
}

.other-treatment-checkboxes {
  color: #3a72a6;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 24px;
  row-gap: 16px;
}

.options-row {
  color: #3a72a6;
  margin-top: 20px;
}

.other-treatment-text-row .text-field {
  width: 500px;
}

.clinical-test-matrix {
  display: grid;
  grid-template: auto/ 247px 247px 247px 247px;
  row-gap: 37px;
  padding-top: 30px;
}

.clinical-test-cell {
  display: flex;
  justify-content: space-between;
}

.clinical-test-cell-divider {
  height: 56px;
  width: 1px;
  background-color: #e6e6e6;
  margin-right: 20px;
  margin-top: 50px;
}

/*Small screen (Mobile) */
.container{
  padding: 0;
  align-items: center;
}

.patient-finished-headers{
  color: #FFFFFF;
  text-align: center;
}

.patient-finished-headers h1{
  font-size: 40px;
  margin: 0;

}

.patient-finished-headers h2{
  font-size: 30px;
  margin: 0;
}

.patient-finished-instruction {
  color: #ffffff;
  text-align: center;
  font-size: 14px;
  margin: 0 10%;
}

#fill_the_form {
  margin-top: 20px;
  position: relative;
  top: -480px;
  color: #ffffff;
  background-color: #32659c;
}

/* Medium screen (Tablet) */
@media only screen and (min-width: 600px) {}

/* Big screen like laptop and desktop pc*/
@media only screen and (min-width: 768px) {
  .patient-finished-headers h1{
    font-size: 80px;
  
  }
  
  .patient-finished-headers h2{
    font-size: 40px;
  }

  .patient-finished-instruction {
    font-size: 24px;
    margin: 0;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Kulim+Park&family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300&display=swap");

.admin-login-headers h1,
.admin-login-headers h2 {
  color: #ffffff;
  text-align: center;
}

.admin-login-headers h1 {
  font-size: 60px;
  font-weight: 100;
  margin: 0px;
}

.admin-login-headers h2 {
  font-size: 30px;
  font-weight: 100;
  margin-top: 7px;
}

.admin-login-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.admin-login-container {
  background-color: #fafafa;
  width: 520px;
  height: 634px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.admin-login-container h1 {
  color: #477bab;
  font-family: "Kulim Park", sans-serif;
  font-size: 32px;
  padding: 60px;
}

.admin-login-button-container {
  width: 100%;
}
/* Medium screen (Tablet) */
@media only screen and (min-width: 600px) {
}

/* Big screen like laptop and desktop pc*/
@media only screen and (min-width: 768px) {
}

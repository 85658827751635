/*Small screen (Mobile) */
.patient-landing-instruction {
  color: #ffffff;
  text-align: center;
}

.container{
  height: 100vh;
  background-image: url('../../images/patient_landing_gradient.svg');
  background-color: #549bdc;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.patient-landing-headers {
  color: #ffffff;
  text-align: center;
}

.patient-landing-headers h1{
  font-size: 40px;
}

.patient-landing-headers h2{
  font-size: 20px;
  margin: 0;
}

.patient-landing-illustration img {
  display: block;
  height: 200px;
  margin: auto;
}

.patient-landing-instruction {
  font-size: 14px;
  margin: 0 20%;
}

#fill_the_form {
  margin-top: 20px;
  color: #ffffff;
  background-color: #32659c;
}

.button-action{
  position: static!important;
}

/* Medium screen (Tablet) */
@media only screen and (min-width: 600px) {}

/* Big screen like laptop and desktop pc*/
@media only screen and (min-width: 768px) {
  .patient-landing-headers h1{
    font-size: 60px;
  }
  
  .patient-landing-headers h2{
    font-size: 30px;
  }

  .patient-landing-instruction {
    font-size: 21px;
    margin: 0;
  }
}
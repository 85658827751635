@media (hover) {
  .date-field-input:hover {
    outline: none !important;
    border: solid 1px #3a72a6;
  }
}

.date-field {
  padding: 0px 0px 0px 0px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  width: 130px;
}

.date-field time {
  width: 200px;
}

.ant-picker {
  border: solid 1px #b0d6f9;
  border-radius: 4px;
}

.date-field-input {
  width: 100%;
  height: 50px;
  margin-top: 4px;
  border: solid 1px #b0d6f9;
  border-radius: 4px;
  text-indent: 6px;
}

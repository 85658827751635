.span-2.multiple-options{
  width: 90%;
  padding: 16px;
  padding-right: 0;
}

.multiple-options {
  width: 100%;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
}

.button-multiple-option {
  background-color: #ffffff;
  color: #b0d6f9;
  font-family: inherit;
  border: 1px;
  border-color: #b0d6f9;
  border: 1px solid;
  border-radius: 10rem;
  height: 40px;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  font-weight: 400;
  font-size: 1rem;
  cursor: pointer;
  margin: 16px 16px 0px 0px;
}

.button-multiple-option-error{
  background-color: #ffffff;
  color: #ff4f6e;
  font-family: inherit;
  border: 1px;
  border-color: #ff4f6e;
  border: 1px solid;
  border-radius: 10rem;
  height: 40px;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  font-weight: 400;
  font-size: 1rem;
  cursor: pointer;
  margin: 16px 16px 0px 0px;
}

.button-multiple-selected {
  background-color: #549bdc;
  color: #ffffff;
  border-color: #549bdc;
}

.button-multiple-option:disabled {
  border: solid 1px #c9d3dd;
  color: #c9d3dd;
  background-color: #fff;
}

@media (hover) {
  .button-multiple-option:hover {
    color: #549bdc;
    border-color: #549bdc;
  }
}

/* Medium screen (Tablet) */
@media only screen and (min-width: 600px) {}

/* Big screen like laptop and desktop pc*/
@media only screen and (min-width: 768px) { }
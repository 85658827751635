.checkbox-endo {
  padding: 0px 0px 0px 0px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
}

.checkbox-input {
  border: solid 1px #b0d6f9;
  border-radius: 4px;
}

/* Customize the label (the checkbox-label) */
.checkbox-label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkbox-checkmark {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 24px;
  width: 24px;
  background-color: #fff;
  border: solid 1px;
  border-color: #2196f3;
  border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.checkbox-label:hover .checkbox-checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-label input:checked ~ .checkbox-checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkbox-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-label input:checked ~ .checkbox-checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-label .checkbox-checkmark:after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0px 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.short-text {
  display: flex;
  flex-direction: row;
  padding: 0px 0px 0px 0px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 18px;
}

.short-text .form-label {
  width: max-content;
}

.short-text-input {
  height: 16px;
  margin-top: 4px;
  margin-left: 8px;
  border: solid 1px #000;
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: transparent;
  text-indent: 2px;
  width: 25px;
  font-size: 18px;
}

.button-action {
  background-color: #32659c;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  border: none;
  border-radius: 30px;
  padding: 14px 24px;
  height: 72px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
}

.button-action-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

/* Medium screen (Tablet) */
@media only screen and (min-width: 600px) {}

/* Big screen like laptop and desktop pc*/
@media only screen and (min-width: 768px) {
  .button-action {
    height: 60px;
    font-size: 18px;
  }
}
.exam-and-radiographic-container {
  width: auto;
  display: grid;
  column-gap: 30px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  justify-content: center;
  background-color: #fafafa;
  padding: 30px;
}

.tooth-selector {
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
}

.tooth-selector-header {
  display: grid;
  grid-template-columns: auto min-content;
  background-color: #efefef;
  height: 143px;
  font-family: "Kulim Park", sans-serif;
  color: #333;
}

.tooth-selector-header-title {
  font-size: 50px;
  padding-left: 30px;
  padding-top: 30px;
}
.tooth-selector-header-current-tooth-text {
  font-size: 16px;
  padding-left: 30px;
  padding-top: 5px;
}

.tooth-selector-header-delete-icon button {
  padding: 30px;
  border: none;
}

.teeth {
  background-color: #000;
  display: block;
}

.teeth img {
  height: 300px;
  width: 100%;
  object-fit: cover;
}

.tooth-delete-button {
  background-color: transparent;
}

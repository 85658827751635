.radio-button-options {
  width: 100%;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
}

.radio-button-options-buttons {
  width: 100%;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.radio-button-option {
  color: #b0d6f9;
}
.radio-button-option-error {
  color: #ff4f6e;
}

/* Customize the label (the container) */
.radio-button-label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 400;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #b0d6f9;
}

.radio-button-label-error {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 400;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #ff4f6e;
}

/* Hide the browser's default radio button */
.radio-button-label input,
.radio-button-label-error input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: transparent;
  border-radius: 50%;
  border-color: #b0d6f9;
  border: 1px solid;
  color: #b0d6f9;
}

.checkmark-error {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: transparent;
  border-radius: 50%;
  border-color: #ff4f6e;
  border: 1px solid;
  color: #ff4f6e;
}

/* On mouse-over, add a grey background color */
.radio-button-label:hover input ~ .checkmark,
.radio-button-label-error:hover input ~ .checkmark-error {
  background-color: #549bdc;
}

/* When the radio button is checked, add a blue background */
.radio-button-label input:checked ~ .checkmark,
.radio-button-label-error input:checked ~ .checkmark-error {
  background-color: #2196f3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkmark-error:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-button-label input:checked ~ .checkmark:after,
.radio-button-label-error input:checked ~ .checkmark-error:after {
  display: block;
}

.radio-button-label input:hover ~ .checkmark:after,
.radio-button-label-error input:hover ~ .checkmark-error:after {
  display: block;
  top: 4px;
  left: 4px;
  width: 14px;
  height: 14px;
}

/* Style the indicator (dot/circle) */
.radio-button-label .checkmark:after,
.radio-button-label-error .checkmark-error:after {
  top: 7px;
  left: 7px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

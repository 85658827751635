.diagnosis {
  background-color: #b0d6f9;
}

.diagnosis-title {
  font-family: "Kulim Park", sans-serif;
  color: #333;
  font-size: 50px;
  padding-left: 30px;
  padding-top: 30px;
}

.diagnosis-subtitle {
  font-size: 16px;
  padding-left: 30px;
  padding-top: 5px;
  padding-bottom: 30px;
}

.diagnosis-text {
  font-family: "Kulim Park", sans-serif;
  font-size: 50px;
  padding-left: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.diagnosis-action-button-container {
  padding: 30px;
}

.diagnosis-action-button-container .button-action {
  background-color: #32659c;
  color: #ffffff;
}

.diagnosis-edit {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  background-color: #fafafa;
}

.diagnosis-back-button {
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  margin-left: 25px;
  margin-top: 30px;
}
.diagnosis-back-button img {
  margin-right: 12px;
}

.diagnosis-edit .multiple-options {
  margin: 0px 35px 0px 35px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 22px;
}

.diagnosis-edit .diagnosis-title {
  padding-top: 16px;
}

.diagnosis-edit-ruler {
  margin-left: 30px;
  margin-right: 30px;
}

@media (hover) {
  .searchbar:has(input:hover) {
    width: 400px;
    height: 50px;
    background-color: #fff;
    display: flex;
    border-radius: 50px;
    border: solid #549bdc 1px;
    margin: auto;
  }
}

.admin-dashboard-header {
  display: grid;
  grid-template: 100px /320px auto 320px;
  background-color: #efefef;
  width: 100%;
  height: 100px;
  align-items: center;
  /* position: fixed; */
}

.admin-dashboard-header-divider {
  width: 100%;
  height: 1px;
  background-color: #c9d3dd;
  grid-column: span 2;
}

.admin-dashboard-content-layout {
  width: 100%;
  display: grid;
  grid-template: 50px auto 50px / auto 1094px auto;
  /* margin-top: 100px; */
}

.admin-dashboard-inner-layout {
  width: 100%;
  display: grid;
  grid-template:
    "b a" 90px
    "b c" auto
    / 522px 522px;
  row-gap: 50px;
  column-gap: 50px;
}

.create-new-patient-record {
  width: 100%;
  height: 100%;
  font-size: 22px;
  font-family: "Open Sans", sans-serif;
  color: #333;
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 20px;
}

.create-new-patient-record button {
  background-color: #549bdc;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  border: none;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  font-weight: 200;
  font-size: 44px;
  cursor: pointer;
  padding: 0px;
  vertical-align: center;
}

.logo {
  padding-left: 25px;
}

.searchbar {
  width: 400px;
  height: 50px;
  background-color: #fff;
  display: flex;
  border-radius: 50px;
  border: solid #b0d6f9 1px;
  margin: auto;
}

.searchbar:has(input:focus) {
  width: 400px;
  height: 50px;
  background-color: #fff;
  display: flex;
  border-radius: 50px;
  border: solid #549bdc 1px;
  margin: auto;
}

.searchbar-form {
  display: flex;
  align-items: center;
}

.search-icon {
  margin-left: 25px;
}

.search-text-field {
  width: 300px;
  height: 20px;
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  text-indent: 16px;
}

.search-text-field:focus {
  outline: none !important;
  /* border: solid 1px #549bdc; */
}

.search-results {
  font-size: 22px;
  font-family: "Open Sans", sans-serif;
  color: #333;
  display: grid;
  grid-template: 90px auto/ auto;
  min-height: 371px;
}

.search-results-header {
  display: grid;
  grid-template: 89px 1px / 65px auto;
  align-items: center;
  width: 100%;
}

.search-results-header img {
  padding-left: 30px;
  padding-right: 16px;
}

.search-results-content {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.search-results-no-patient {
  font-size: 16px;
  font-style: italic;
  font-family: "Open Sans", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search-results-row {
  width: 100%;
  height: 85px;
  display: grid;
  align-items: center;
  grid-template-columns: 100px auto 137px;
  column-gap: 30px;
  color: #333;
}

.search-results-row-date {
  font-size: 12px;
}

.search-results-row button {
  background-color: #549bdc;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  border: none;
  border-radius: 2px;
  height: 40px;
  width: 137px;
  font-weight: 200;
  font-size: 15px;
  cursor: pointer;
}

.search-results-no-patient-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 281px;
  height: 281px;
}

.search-results-no-patient-content img {
  width: 200px;
  height: 200px;
}

.todays-appointments {
  font-size: 22px;
  font-family: "Open Sans", sans-serif;
  color: #333;
  display: grid;
  grid-template: 90px auto/ auto;
}

.todays-appointments-header {
  display: grid;
  grid-template: 89px 1px / 65px auto;
  align-items: center;
  width: 100%;
}

.todays-appointments-header img {
  padding-left: 30px;
  padding-right: 16px;
}

.todays-appointments-content {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.todays-appointments-row {
  width: 100%;
  height: 85px;
  display: grid;
  align-items: center;
  grid-template-columns: 100px auto 137px;
  column-gap: 30px;
  color: #333;
}

.todays-appointments-row button {
  background-color: #549bdc;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  border: none;
  border-radius: 2px;
  height: 40px;
  width: 137px;
  font-weight: 200;
  font-size: 15px;
  cursor: pointer;
}

.white-box {
  background-color: #fafafa;
  border-radius: 4px;
}

.create-patient-record-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  /* background-color: #efefef; */
  height: 100%;
  font-size: 22px;
  color: #333;
}

.create-patient-record-container .text-field {
  width: 400px;
  color: #3a72a6;
}

.create-patient-record-container .date-field {
  width: 160px;
  color: #3a72a6;
}

.create-patient-record-close-button-wrapper {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}

.create-patient-record-close-button {
  width: 44px;
  height: 44px;
  border: none;
  outline: none;
  background-color: transparent;
}

.create-patient-record-date-referred {
  display: flex;
  gap: 20px;
}

#create_patient_save {
  margin-top: 50px;
}

.alert-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

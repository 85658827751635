.clinical-test {
  display: flex;
  flex-direction: column;
}

.clinical-test-title {
  font-size: 22px;
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
}

.clinical-test-wrapper {
  background-color: #fafafa;
  padding: 30px;
  border-radius: 4px;
  box-shadow: 0px 4px 4px 0px #00000050;
}

.clinical-test-tab-select {
  padding-top: 20px;
}

.clinical-test-tab-divider {
  background-color: #549bdc;
  height: 1px;
}

.clinical-test-tab-button {
  width: 100px;
  height: 50px;
  border: none;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #b0d6f9;
  box-shadow: 2px 0px 4px 0px #33333360;
  font-size: 22px;
  color: #ffffff;
}

.clinical-test-tab-button-selected {
  width: 100px;
  height: 50px;
  border: none;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #549bdc;
  box-shadow: 2px 0px 4px 0px #33333360;
  font-size: 22px;
  color: #ffffff;
}

.clinical-test .text-field {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  color: #3a72a6;
}

.clinical-test .text-field-input {
  width: 111px;
  height: 50px;
}

.span-2.text-area{
  width: calc(100% - 32px);
}

.text-area {
  padding: 24px 16px 0 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
}

.text-area-input {
  width: 100%;
  height: 160px;
  margin-top: 16px;
  border: solid 1px #b0d6f9;
  border-radius: 4px;
  text-indent: 16px;
  resize: none;
  font-family: inherit;
  padding-top: 16px;
  font-size: 16px;
}

.text-area-input::placeholder {
  font-size: 14px;
}

.text-area-input:focus {
  outline: none !important;
  border: solid 1px #3a72a6;
}

.text-area-input:disabled {
  border: solid 1px #c9d3dd;
  background-color: #fff;
}

/* Medium screen (Tablet) */
@media only screen and (min-width: 600px) {}

/* Big screen like laptop and desktop pc*/
@media only screen and (min-width: 768px) {
  @media (hover) {
    .text-area-input:hover {
      outline: none !important;
      border: solid 1px #3a72a6;
    }
  }

  .text-area {
    padding: 0;
  }

  .text-area-input {
    height: 84px;
    margin-top: 4px;
  }
}
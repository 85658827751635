.recommended-and-canal {
  background-color: #fafafa;
  display: grid;
  grid-template:
    "a b" auto
    "c b" auto
    / 537px 537px;
  column-gap: 20px;
  row-gap: 30px;
  border-radius: 4px;
}

.recommended-canal-tx-container {
  display: grid;
  grid-template: repeat(5, 66px) / auto 86px 86px;
  grid-row: span 2;
  margin: 20px;
  align-items: center;
  border: solid 1px;
  border-color: #b0d6f9;
}

.recommended-canal-tx-container > div:nth-child(15n + 1),
.recommended-canal-tx-container > div:nth-child(15n + 2),
.recommended-canal-tx-container > div:nth-child(15n + 3) {
  background: #b0d6f9;
  height: 100%;
  display: flex;
  align-items: center;
}

.recommended-canal-tx-container > div:nth-child(15n + 1) span {
  padding-left: 16px;
}

.recommended-canal-tx-container > div:nth-child(15n + 2),
.recommended-canal-tx-container > div:nth-child(15n + 3) {
  justify-content: center;
}

.recommended-canal-tx-container .text-field-input {
  width: 64px;
  height: 40px;
}

.recommended-canal-tx-container .button-multiple-option {
  margin-top: 0px;
}

.recommended-canal-tx-container .text-field-cell {
  display: flex;
  justify-content: center;
}

.recommended-canal-tx-container .options-cell {
  padding-left: 16px;
}
